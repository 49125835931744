export const addRandomDigits = (str) => {
    // Generate a random 4-digit number
    const randomFourDigits = Math.floor(1000 + Math.random() * 9000);
    
    // Generate a random 2-digit number
    const randomTwoDigits = Math.floor(10 + Math.random() * 90);
    
    // Concatenate the random numbers with the input string
    const result = `${randomFourDigits}${str}${randomTwoDigits}`;
    
    return result;
}