import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PatientsTable from "../../components/PatientsTable/PatientsTable";
import Subtitle from "../../components/Subtitle/Subtitle";
import { saveSteps } from "../../reducers/appointmentInfoSlice";
import { useTranslation } from 'react-i18next';

const StepThree = () => {

  const { t } = useTranslation();
  const session = useSelector((state) => state.placeAppointment.session);
  const sessionId = session?.sessionId;
  const appointmentDateTime = session?.timeStart;
  const dispatch = useDispatch();
  const patientId = useSelector((state) => state.appointmentInfo.patientId);
  const [selectedPatientError, setSelectedPatientError] = useState({
    isVisible: false,
    message:  t('PleaseSelectPatient')
  });
  return (
    <Grid item xs={12} sm lg sx={{ minHeight: "100%" }}>
      <Subtitle
        title={""}
        sessionId={sessionId}
        appointmentDateTime={appointmentDateTime}
      />

      <Box mt={2}>
        <Typography
          fontWeight={"bold"}
          fontSize={"1.3rem"}
          sx={{
            mb: 1,
          }}
        >
          {t('SelectPatientFromList')}
        </Typography>
        {selectedPatientError.isVisible && (
          <Typography
            sx={{
              fontSize: "0.8rem",
              color: "error.main",
            }}
          >
            *{selectedPatientError.message}
          </Typography>
        )}
        <PatientsTable />
      </Box>

      <Box mt={1} display="flex" justifyContent={"start"}>
        <Button
          variant="contained"
          onClick={() => {
            if (patientId !== "") {
              setSelectedPatientError({
                isVisible: false,
                message:  t('PleaseSelectPatient')  ,
              });
              dispatch(
                saveSteps({
                  step: 4,
                  name: "",
                  specialization: "",
                  date: "",
                })
              );
            } else {
              setSelectedPatientError({
                isVisible: true,
                message: t('PleaseSelectPatient') ,
              });
            }
          }}
        >
        {t('Continue')}
        </Button>
      </Box>
    </Grid>
  );
};

export default StepThree;
