import styled from "@emotion/styled";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { order } from "../../app/apiManager/apiManager";
import CustomModal from "../../components/CustomModal/CustomModal";
import LinkText from "../../components/LinkText/LinkText";
import { INSURANCE_APPROVAL_STATUS, PAYMENT_METHODS } from "../../const";
import { setPaymentDetails } from "../../reducers/appointmentInfoSlice";
import { convertToCurrency } from "../../utils/convert-to-currency";
import { useTranslation } from 'react-i18next';
import getOriginalDateTime from "../../utils/formatAMPM";
import ShowAlert from "./ShowAlert";
import AddInsuranceInfoModal from "../../modals/AddInsuranceInfoModal";
import InsuranceService from "../../app/apiManager/insuranceService";
import InsuranceStatusModal from "../../modals/InsuranceStatusModal";

const StepFour = ({
  setShowing,
  setSideMenuOpen,
  setPaymentStatus,
  doctorId,
  makeAppontment,
  isOtherCountry,
  doctor,
  paymentStatus,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const session = useSelector((state) => state.placeAppointment.session);

  const channelingFee = isOtherCountry
    ? parseInt(process.env.REACT_APP_CHANNELING_FEE_OTHER) +
      parseInt(process.env.REACT_APP_SERVICE_FEE_OTHER)
    : doctor.doctorFee + doctor.hospitalFee + doctor.otherFee;

  const sessionId = session?.sessionId;
  const loadingStatus = useSelector((state) => state.loadingStatus);
  const appointmentDateTime = session?.timeStart;
  const patientId = useSelector((state) => state.appointmentInfo.patientId);
  const patientName = useSelector((state) => state.appointmentInfo.patientName);
  const patientMobileNo = useSelector((state) => state.appointmentInfo.phoneNo);
  const [showAddInsuranceModal, setShowAddInsuranceModal] = useState(false);
  const [showInsuranceStatusModal, setShowInsuranceStatusModal] =
    useState(false);
  const [insuranceStatusText, setInsuranceStatusText] = useState("");

  const patientEmail = useSelector(
    (state) => state.appointmentInfo.patientEmail
  );
  const sessionType = useSelector((state) => state.appointmentInfo.sessionType);

  const StyledTextField = styled(TextField)({
    ".MuiInput-underline": {
      marginTop: 4,
    },
  });
  const [promoCode, setPromoCode] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [paymentOption, setPaymentOption] = useState(PAYMENT_METHODS.PAY_NOW);
  const [isFree, setIsFree] = useState(false);
  // const [isFree, setIsFree] = useState( appointmentDateTime.toLocaleString('en-us', {year: 'numeric', month: '2-digit', day: '2-digit'}).
  // replace(/(\d+)\/(\d+)\/(\d+)/, '$3-$1-$2')=="2023-04-05");

  const dispatch = useDispatch();

  const calculateEstimatedAppointmentStartTime = (dateTime) => {
    dateTime = new Date(dateTime);
    dateTime = dateTime.setMinutes(dateTime.getMinutes() + 15);
    return dateTime;
  };

  const conversionRatio = useSelector(
    (state) => state.conversionRatio.conversionRatio
  );

  useEffect(() => {
    // console.log(appointmentDateTime);
    if (isFree) {
      setShowPopup(true);
    }
  }, [isFree]);

  const isPromoInvalid =
    promoCode.length > 0 && promoCode.toLowerCase() !== "staff";

  const makeInsurancePayment = async () => {
    try {
      const availableInsurance = await InsuranceService.getInsuranceByPatientId(
        {
          patientId,
        }
      );
      console.log(availableInsurance);

      if (availableInsurance?.data?.ApprovalStatus) {
        //has an insurance check for status if approved make the appointment, if not show the error modal
        const approvalStatus = availableInsurance?.data?.ApprovalStatus;
        if (approvalStatus === INSURANCE_APPROVAL_STATUS.PENDING) {
          //show the error modal
          // setShowAddInsuranceModal(true);
          setInsuranceStatusText("Please wait for Insurance Approval");
          setShowInsuranceStatusModal(true);
          setPaymentStatus({
            isVisible: false,
            content: "loading",
          });
        } else if (approvalStatus === INSURANCE_APPROVAL_STATUS.REJECTED) {
          //show the error modal
          // setShowAddInsuranceModal(true);
          setInsuranceStatusText("Insurance Rejected");
          setShowInsuranceStatusModal(true);
          setPaymentStatus({
            isVisible: false,
            content: "loading",
          });
        } else if (
          approvalStatus === INSURANCE_APPROVAL_STATUS.APPROVED &&
          new Date(availableInsurance?.data?.ExpiryDate) < new Date()
        ) {
          //show the error modal
          // setShowAddInsuranceModal(true);
          setInsuranceStatusText("Insurance Expired");
          setShowInsuranceStatusModal(true);
          setPaymentStatus({
            isVisible: false,
            content: "loading",
          });
        } else if (approvalStatus === INSURANCE_APPROVAL_STATUS.APPROVED) {
          //make the appointment
          // makeAppontment();
          makeAppontment(PAYMENT_METHODS.PAY_BY_INSURANCE);
        }
      } else {
        // no insurance found
        //show the add insurance modal
        setShowAddInsuranceModal(true);
        setPaymentStatus({
          isVisible: false,
          content: "loading",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Grid item xs={12} sm lg sx={{ height: "30rem", mt: { xs: 2, sm: 0 } }}>
      <AddInsuranceInfoModal
        open={showAddInsuranceModal}
        setOpen={setShowAddInsuranceModal}
  
      />
      <InsuranceStatusModal
        open={showInsuranceStatusModal}
        setOpen={setShowInsuranceStatusModal}
        textContent={insuranceStatusText}
      />
      <div>
        <Typography fontWeight={"bold"} fontSize={"1.3rem"}>
        {t('AppointmentInfo')}
        </Typography>
        <Box mt={2}>
          <Grid container>
            <Grid item xs>
              <Typography fontWeight={"medium"}> {t('RefNo')}</Typography>
              <Typography>{sessionId} </Typography>
            </Grid>
            <Grid item xs>
              <Typography fontWeight={"bold"}> {t('AppointmentDate')} </Typography>
              <Typography>
                {getOriginalDateTime(appointmentDateTime).toLocaleDateString()}
              </Typography>
            </Grid>
          </Grid>
          <Grid container mt={2}>
            <Grid item fontWeight={"bold"} xs={5.5}>
              <Typography fontWeight={"bold"}>{t('PatientName')}</Typography>
              <Typography>{patientName}</Typography>
            </Grid>
            <Grid item xs>
              <Typography fontWeight={"bold"}>{t('AppointmentTime')}</Typography>
              <Typography>
                {format(getOriginalDateTime(appointmentDateTime), "p")}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </div>

      <Grid container mt={2}>
        {patientMobileNo.length > 0 && (
          <Grid item xs={5.5}>
            <Typography fontWeight={"bold"}>{t('MobileNo')}</Typography>
            <Typography>{patientMobileNo}</Typography>
          </Grid>
        )}

        {patientEmail ? (
          <Grid item xs>
            <Typography fontWeight={"bold"}>{t('email')}</Typography>
            <Typography>{patientEmail}</Typography>
          </Grid>
        ) : null}
      </Grid>
      <Divider
        orientation="horizontal"
        sx={{
          backgroundColor: "#ABABAB",
          mt: 2,
          mb: 2,
        }}
      />

      <Grid container mt={2} gap={2}>
        <Grid item xs>
          <Typography fontWeight={"bold"}>{t('Amount')} </Typography>
          <StyledTextField
            readOnly
            label=" "
            fullWidth
            variant="standard"
            sx={{
              mt: 0,
            }}
            inputProps={{ readOnly: true }}
            value={
              isOtherCountry
                ? `${process.env.REACT_APP_CURRENCY} 10.00`
                : convertToCurrency({
                    amount:
                      parseFloat(
                        doctor.doctorFee + doctor.hospitalFee + doctor.otherFee
                      ) * conversionRatio,
                    currency: process.env.REACT_APP_CURRENCY,
                  })
            }
          />

          <Typography></Typography>
        </Grid>

        <Grid item xs>
          <Typography fontWeight={"bold"}>
          {t('PromoCode')}
            {isPromoInvalid && (
              <span
                style={{
                  color: "red",
                  fontSize: "10px",
                }}
              >
                {" "}
                {t('InvalidCode')}
              </span>
            )}
          </Typography>
          <TextField
            sx={{
              mt: 1,
            }}
            variant="standard"
            onChange={(event) => {
              const value = event.target.value;
              setPromoCode(value);
            }}
            value={promoCode}
            fullWidth
            size="small"
            error={isPromoInvalid}
          />
        </Grid>
      </Grid>

      <Grid container mt={2}>
        <RadioGroup row defaultValue={PAYMENT_METHODS.PAY_NOW}>
          <FormControlLabel
            value={PAYMENT_METHODS.PAY_NOW}
            control={
              <Radio
                onClick={() => {
                  setPaymentOption(PAYMENT_METHODS.PAY_NOW);
                }}
                disabled={!isPromoInvalid && promoCode.length > 0}
              />
            }
            label={t('PayOnline')}
          />
          <FormControlLabel
            value={PAYMENT_METHODS.PAY_BY_INSURANCE}
            control={
              <Radio
                onClick={() => {
                  setPaymentOption(PAYMENT_METHODS.PAY_BY_INSURANCE);
                }}
                disabled={!isPromoInvalid && promoCode.length > 0}
              />
            }
            label="Pay by Insurance"
          />
        </RadioGroup>
      </Grid>
      {/* {PAYMENT_METHODS.PAY_BY_INSURANCE === paymentOption && (
        <Box display={"flex"}>
          <Box flexGrow={1} display={"flex"} alignItems={"end"}>
            <Typography fontWeight={"bold"}>Insurance Number</Typography>
          </Box>
          <Box flexGrow={1}>
            <TextField
              sx={{
                mt: 1,
              }}
              variant="standard"
              onChange={(event) => {
                const value = event.target.value;
                setPromoCode(value);
              }}
              value={promoCode}
              fullWidth
              size="small"
              error={isPromoInvalid}
            />
          </Box>
        </Box>
      )} */}

      <Typography sx={{ my: 1 }}>
      {t('ByClickingComplete')} {" "}
        <LinkText
          text={t('T&C')}
          onClick={() => {
            setShowing(1);
            setSideMenuOpen(true);
          }}
        />
      </Typography>

      <Box mt={2} display="flex" gap={2}>
        <Button
          variant="contained"
          disabled={isPromoInvalid || paymentStatus?.isVisible}
          onClick={() => {
            setPaymentStatus({
              isVisible: true,
              content: "loading",
            });

            if (
              paymentOption === PAYMENT_METHODS.PAY_NOW &&
              promoCode.toLowerCase() !== "staff" &&
              !isFree
            ) {
              // dispatch(
              //   setAppointmentId({
              //     appointmentId: data.Id,
              //   })
              // );
              order(
                doctorId,
                channelingFee,
                isOtherCountry,
                patientEmail,
                patientMobileNo,
                patientId,
                (data) => {
                  let payUrl = data?.data?.data?.payUrl;
                  if (payUrl) {
                    let reference = data.reference;
                    let trnId = payUrl.substring(payUrl.indexOf("=") + 1);
                    setPaymentStatus({
                      isVisible: false,
                      content: "",
                    });
                    dispatch(
                      setPaymentDetails({
                        txnId: trnId,
                        reference: reference,
                      })
                    );
                    window.location.replace(payUrl);
                  } else {
                    setPaymentStatus({
                      isVisible: true,
                      content: "failed",
                    });
                  }
                },
                () => {
                  //TODO: handle error
                  setPaymentStatus({
                    isVisible: true,
                    content: "failed",
                  });
                }
              );
            } else if (
              paymentOption === PAYMENT_METHODS.CASH_PAYMENT &&
              promoCode.toLowerCase() !== "staff" &&
              !isFree
            ) {
              setPaymentStatus({
                isVisible: false,
                content: "",
              });
              makeAppontment(PAYMENT_METHODS.CASH_PAYMENT);
            } else if (
              paymentOption === PAYMENT_METHODS.PAY_BY_INSURANCE &&
              promoCode.toLowerCase() !== "staff" &&
              !isFree
            ) {
              //do the insurance payment
              makeInsurancePayment();
              console.log("Pay by insurance");
            } else {
              setPaymentStatus({
                isVisible: false,
                content: "",
              });
              makeAppontment(PAYMENT_METHODS.PROMO);
            }
          }}
        >
          Continue{" "}
          {paymentStatus?.isVisible && (
            <CircularProgress
              size={15}
              sx={{
                ml: 1,
                color: "white",
              }}
            />
          )}
        </Button>
      </Box>
      <CustomModal open={showPopup}>
        <ShowAlert
          onClick={() => {
            setShowPopup(false);
            // setPaymentStatus({ isVisible: false, content: "" });
            // dispatch(
            //     setStep({
            //         step: 0,
            //     })
            // );
            // navigate(`/status?appointmentId=${appointmentId}`);
          }}
        />
      </CustomModal>
    </Grid>
  );
};

export default StepFour;
