import { PRODUCTION } from "../../const";
import { clearLoadingStatus } from "../../reducers/loadersSlice";
import { store } from "../store";

export const post = (
  path,
  body,
  setLoadingStatusFor = "",
  onSuccess = () => {},
  onFailed = () => {},
  onComplete = () => {}
) => {
  store.dispatch({
    type: "SET_LOADING_FOR",
    payload: { loadingAction: setLoadingStatusFor },
  });
  fetch(`${process.env.REACT_APP_BASE_URL}${path}`, {
    method: "POST",
    headers: {
      "Content-type": "application/json",
      UserId: "2",
    },
    body: JSON.stringify(body),
  })
    .then((res) => res.json())
    .then((data) => {
      onSuccess(data.Data, data);
    })
    .catch((error) => {
      onFailed(error);
    })
    .finally(() => {
      onComplete();
      store.dispatch({
        type: "CLEAR_LOADING_FOR",
        payload: { loadingAction: setLoadingStatusFor },
      }); // removes animation whatever happened
    });
};

export const postAsync = async (
  path,
  body,
  onSuccess = () => {},
  onFailed = () => {},
  onComplete = () => {}
) => {
  await fetch(
    `${
      true ? process.env.REACT_APP_BASE_URL : process.env.REACT_APP_BASE_URL_DEV
    }${path}`,
    {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(body),
    }
  )
    .then((res) => res.json())
    .then((data) => {
      onSuccess(data.Data, data);
    })
    .catch((error) => {
      console.log(error);
      onFailed(error);
    })
    .finally(() => {
      onComplete();
    });
};

export const postAsyncV2 = async (path, data) => {
  return fetch(`${process.env.REACT_APP_BASE_URL}${path}`, {
    method: "POST",
    headers: {
      "Content-type": "application/json",
      UserId: "2",
    },
    body: JSON.stringify(data),
  });
};

export const appoitnmentStatus = (
  body,
  onSuccess = () => {},
  onFailed = () => {},
  onComplete = () => {}
) => {
  fetch(
    `${
      PRODUCTION
        ? process.env.REACT_APP_BASE_URL
        : process.env.REACT_APP_BASE_URL_APPOINTMENT_STATUS
    }`,
    {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(body),
    }
  )
    .then((res) => res.json())
    .then((data) => {
      onSuccess(data.Data, data);
    })
    .catch((error) => {
      onFailed(error);
    })
    .finally(() => {
      onComplete();
    });
};

export const viewDoctorStatus = (
  body,
  onSuccess = () => {},
  onFailed = () => {},
  onComplete = () => {}
) => {
  fetch(`${process.env.REACT_APP_BASE_URL_DOCTOR_STATUS}doctor/status/view`, {
    method: "POST",
    headers: {
      "Content-type": "application/json",
    },
    body: JSON.stringify(body),
  })
    .then((res) => res.json())
    .then((response) => {
      onSuccess(response);
    })
    .catch((error) => {
      onFailed(error);
    })
    .finally(() => {
      onComplete();
    });
};

export const getAppointmentPrescription = (
  appointmentId,
  onSuccess = () => {},
  onFailed = () => {},
  onComplete = () => {}
) => {
  fetch(`${process.env.REACT_APP_BASE_URL_DOCTOR_STATUS}prescription/get`, {
    method: "POST",
    headers: {
      "Content-type": "application/json",
    },
    body: JSON.stringify({
      appointmentId,
      country: "BD",
      channel: "Desktop",
      application: "VC",
    }),
  })
    .then((res) => res.json())
    .then((response) => {
      onSuccess(response);
    })
    .catch((error) => {
      onFailed(error);
    })
    .finally(() => {
      onComplete();
    });
};

export const get = async (path, onSuccess = () => {}, onFailed = () => {}) => {
  await fetch(`${process.env.REACT_APP_BASE_URL}${path}`, {
    method: "GET",
    headers: {
      "Content-type": "application/json",
    },
  })
    .then((res) => res.json())
    .then((data) => {
      onSuccess(data.Data);
    })
    .catch((error) => {
      onFailed(error);
    });
};


// --------------------------------- captcha request ---------------------------------

export const getCaptcha = (onSuccess = () => {}, onFailed = () => {}) => {
  fetch(`https://foc.devartsoftware.com/Captcha/4/`, {
    method: "GET",
    headers: {
      "Content-type": "application/json",
    },
  })
    .then((res) => res.json())
    .then((data) => {
      onSuccess(data);
    })
    .catch((error) => {
      onFailed(error);
    });
};

export const verifyCaptcha = async (
  Guid,
  Text,
  onSuccess = () => {},
  onFailed = () => {}
) => {
  await fetch(`https://foc.devartsoftware.com/Captcha/`, {
    method: "POST",
    headers: {
      "Content-type": "application/json",
    },
    body: JSON.stringify({ Guid, Text }),
  })
    .then((res) => res.json())
    .then((data) => {
      onSuccess(data);
    })
    .catch((error) => {
      onFailed(error);
    });
};

export const verifyCaptchaV2 = async (Guid, Text) => {
  const response = await fetch(`https://foc.devartsoftware.com/Captcha/`, {
    method: "POST",
    headers: {
      "Content-type": "application/json",
    },
    body: JSON.stringify({ Guid, Text }),
  });
  return await response.json();
};

// -------------------------- payment gateway -----------------------------------

export const order = (
  doctorId,
  channelingFee,
  isOtherCountry,
  patientEmail,
  patientMobileNo,
  patientId,
  onSuccess = () => {},
  onFailed = () => {}
) => {
  fetch(`${process.env.REACT_APP_IPG_URL}add/order`, {
    method: "POST",
    headers: {
      "Content-type": "application/json",
    },
    body: JSON.stringify({
      extRefId: doctorId, //doctor id
      amount: channelingFee,
      currency: isOtherCountry
        ? process.env.REACT_APP_CURRENCY
        : process.env.REACT_APP_CURRENCY,
      country: "BD",
      returnUrl: process.env.REACT_APP_PAYMENT_CALLBACK_URL,
      customerMail: patientEmail,
      customerMobile: patientMobileNo,
      orderSummary: "Video Consultation Fee",
      customerReference: patientId, //patient id
    }),
  })
    .then((res) => res.json())
    .then((data) => {
      onSuccess(data);
    })
    .catch((error) => {
      onFailed(error);
    });
};

export const checkOrder = (
  merchantRID,
  txnId,
  isOtherCountry,
  onSuccess = () => {},
  onFailed = () => {}
) => {
  fetch(`${process.env.REACT_APP_IPG_URL}check/order`, {
    method: "POST",
    headers: {
      "Content-type": "application/json",
    },
    body: JSON.stringify({
      extRefId: "test", //doctor id
      merchantRID: merchantRID,
      currency: isOtherCountry
        ? process.env.REACT_APP_CURRENCY
        : process.env.REACT_APP_CURRENCY,
      country: "BD",
      txnId: txnId, //id got by transaction
    }),
  })
    .then((res) => res.json())
    .then((data) => {
      onSuccess(data);
    })
    .catch((error) => {
      onFailed(error);
    });
};

export const getIpInfo = (onSuccess = () => {}, onFailed = () => {}) => {
  fetch(`https://api.ipify.org?format=json`, {
    method: "GET",
    headers: {
      "Content-type": "application/json",
    },
  })
    .then((res) => res.json())
    .then((data) => {
      fetch(`https://ipapi.co/${data.ip}/json/`, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
        },
      })
        .then((res) => res.json())
        .then((data) => {
          onSuccess(data);
        })
        .catch((error) => {
          onFailed(error);
        });
    })
    .catch((error) => {
      onFailed(error);
    });
};

// -------------------------- payment gateway -----------------------------------
