export const postBillData = async ({ body }) => {
  const response = await fetch(`${process.env.REACT_APP_MEDICA_URL}Bill/Post`, {
    method: "POST",
    headers: {
      "Content-type": "application/json",
      UserId: 2,
    },
    body: JSON.stringify(body),
  });
  return await response.json();
};

export const updatePaymentStatus = async ({ body }) => {
  const response = await fetch(
    `${process.env.REACT_APP_NEW_API_BASE_URL}bill/update-payment-status`,
    {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        UserId: 2,
      },
      body: JSON.stringify(body),
    }
  );
  return await response.json();
};

export const getAllBillsForAppointment = async (id) => {
  const response = await fetch(
    `${process.env.REACT_APP_NEW_API_BASE_URL}/bill/get?appointmentId=${id}`,
    {
      method: "GET",
      headers: {
        "Content-type": "application/json",
      },
    }
  );
  return await response.json();
};
