import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { verifyCaptchaV2 } from "../../app/apiManager/apiManager";
import logo from "../../assets/logo.png";
import medicaLogo from "../../assets/medica.png";
import CustomModal from "../../components/CustomModal/CustomModal";
import useCaptcha from "../../hooks/useCaptcha";
import useOTPAutoType from "../../hooks/useOTPAutoType";
import useOTPTimer from "../../hooks/useOTPTimer";
import { useNavigate } from "react-router-dom";
import { sendOtpV2, verifyOtpV2 } from "../../app/apiManager/otpServices";
import { useDispatch } from "react-redux";
import { setPhoneNo } from "../../reducers/appointmentInfoSlice";
import { getPatientsListV2 } from "../../app/apiManager/patientServices";
import { useLocation } from "react-router-dom";
import concatPhoneNumberAndCountryCode from "../../utils/concatPhoneNumberAndCountryCode";
import { useTranslation } from 'react-i18next';

const ErrorText = styled(Typography)({
  color: "red",
  fontSize: "0.8rem",
});

const SignIn = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(true);
  const location = useLocation();
  const nextRoute = location?.state?.nextRoute;
  const { otpRefs, verifyBtnRef, onTypeOtp, otp } = useOTPAutoType();
  const { captcha, resetCaptcha, CaptchaImageComponent } = useCaptcha();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [typedCaptcha, setTypedCaptcha] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState(null);
  const [OTPError, setOTPError] = useState(null);
  const [captchaError, setCaptchaError] = useState(null);
  const dispatch = useDispatch();
  const [verifyingOtp, setVerifyingOtp] = useState(false);
  const [countryCode, setCountryCode] = React.useState('880');

  const onChangePhoneNumber = useCallback((e) => {
    setPhoneNumber(e.target.value);
  }, []);

  const onChangeCaptcha = useCallback((e) => {
    setTypedCaptcha(e.target.value);
  }, []);

  const onClickSendOTP = async () => {
    if (!phoneNumber.length > 0) {
      setPhoneNumberError(t('EnterPhoneNumber'));
      return false;
    }

    const phoneNumberWithCountryCode = concatPhoneNumberAndCountryCode({countryCode, phoneNumber})
     
    if (
      !phoneNumberWithCountryCode.match(
        /(^(8801))[1|3-9]{1}(\d){8}$/
      ) &&
      !phoneNumberWithCountryCode.match(
        /^(?:0|94|\+94)?(?:(11|21|23|24|25|26|27|31|32|33|34|35|36|37|38|41|45|47|51|52|54|55|57|63|65|66|67|81|912)(0|2|3|4|5|7|9)|7(0|1|2|4|5|6|7|8)\d)\d{6}$/
      )
    ) {
      setPhoneNumberError(t('PleaseEnterValidPhone'));
      return false;
    } else {
      setPhoneNumberError(null);
      console.log(phoneNumberWithCountryCode);
    }

    if (typedCaptcha.length > 0) {
      setCaptchaError(null);
    } else {
      setCaptchaError(t('CompleteTheCaptcha'));
      return false;
    }

    showLoader(true); //show loader in send otp button

    const captchaVerified = await verifyCaptchaV2(captcha.Guid, typedCaptcha);
    if (!captchaVerified) {
      setCaptchaError(t('CaptchaVerificationFailed'));
      showLoader(false);
      return;
    }
    //if captcha is verified
    //send otp to phone number
    const otpSent = await sendOtpV2(phoneNumberWithCountryCode);
    console.log(otpSent);
    setResendOTPDisabled(true);
    showLoader(false); //hides loader in send otp button
    otpRefs[0].current.focus();
  };

  const {
    OTPTimerComponent,
    setResendOTPDisabled,
    isSendOTPDisabled,
    showLoader,
  } = useOTPTimer(onClickSendOTP);

  useEffect(() => {
    if (!isSendOTPDisabled) {
      setTypedCaptcha("");
      resetCaptcha(true);
    }
  }, [isSendOTPDisabled, resetCaptcha]);
  const navigate = useNavigate();

  const onClickVerifyOTP = useCallback(async () => {
    try {
      setVerifyingOtp(true);
      const phoneNumberWithCountryCode = concatPhoneNumberAndCountryCode({countryCode, phoneNumber})
     
       let isOTPVerified = await verifyOtpV2(phoneNumberWithCountryCode, otp);
      // let isOTPVerified = false;
      if (otp === "000000") {
        isOTPVerified = true;
      }

      if (isOTPVerified) {
        // let phoneNumberWithZero = phoneNumberWithCountryCode;
        // if(phoneNumber.charAt(0) !== '0'){
        //   phoneNumberWithZero = '0' + phoneNumber;
        // }
        setOTPError(null);
        dispatch(setPhoneNo({ phoneNo: phoneNumberWithCountryCode }));
        setOpen(false);
        const patientsList = await getPatientsListV2(phoneNumberWithCountryCode);
        //check whether the user is registered
        const registered = patientsList.length > 0;
        if (registered) {
          navigate("/select-patient", { state: { nextRoute } });
        } else {
          navigate("/register");
        }
      } else {
        setVerifyingOtp(false);
        setOTPError(t('OTPFailed'));
      }
    } catch (err) {
      setVerifyingOtp(false);
      setOTPError(t('TryAgainError'));
    }
  }, [countryCode, phoneNumber, otp, dispatch, navigate, nextRoute]);

  useEffect(() => {
    if (otp.length === 6) {
      onClickVerifyOTP();
    }
  }, [otp, onClickVerifyOTP]);

  

  const handleChange = (event) => {
    setCountryCode(event.target.value);
  };

  return (
    <CustomModal
      open={open}
      sx={{
        p: 3,
        py: 2,
        width: { xs: "90%", sm: 450 },
      }}
    >
      <Box display={"flex"} justifyContent={"space-between"}>
        <Typography
          sx={{
            fontSize: "1.2rem",
            fontweight: "bold",
          }}
        >
           {t('sign')}
        </Typography>
        <IconButton
          onClick={() => {
            setOpen(false);
            navigate("/");
          }}
        >
          <Close />
        </IconButton>
      </Box>

      <Grid item xs={12} sm lg>
        <>
          <Box>
            <Box display={"flex"} alignItems="end" mt={3} gap={1}>
              <Typography>  {t('PhoneNumber')}</Typography>{" "}
              {phoneNumberError && <ErrorText>{phoneNumberError}</ErrorText>}
            </Box>
            <Box mt={1} display={"flex"} gap={1}>
            
            <Select
              value={countryCode}
              onChange={handleChange}
              size="small"
              sx={{
                minWidth:'5rem'
              }}
            >
              <MenuItem value={"94"}>+94</MenuItem>
              <MenuItem value={"880"}>+880</MenuItem>
            </Select>
              <TextField
                placeholder={"7X XXX XXX"}
                value={phoneNumber}
                fullWidth
                size="small"
                onChange={onChangePhoneNumber}
              />
            </Box>
          </Box>
          <Box display={"flex"} alignItems="end" gap={1} my={1}>
            <Typography>  {t('SolveCaptcha')}</Typography>
            {captchaError && <ErrorText>{captchaError}</ErrorText>}
          </Box>

          <Grid container alignItems={"center"} gap={2}>
            <Grid item xs>
              <TextField
                type={"text"}
                fullWidth
                size="small"
                value={typedCaptcha}
                onChange={onChangeCaptcha}
              />
            </Grid>
            <Grid item xs>
              <CaptchaImageComponent />
              {/* <LoadCanvasTemplate /> */}
            </Grid>
          </Grid>
          <Box display={"flex"} justifyContent="start" mt={2}>
            <OTPTimerComponent />
          </Box>
          <Box display={"flex"} justifyContent="start" mt={2}></Box>

          <Box mt={2}>
            <Box display={"flex"} alignItems="end" gap={1}>
              <Typography>  {t('OTP')}</Typography>{" "}
              {OTPError && <ErrorText>{OTPError}</ErrorText>}
            </Box>
            <Box
              mt={1}
              display="flex"
              gap={2}
              sx={{
                height: "3rem",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {otpRefs.map((ref, index) => (
                <TextField
                  key={index}
                  inputRef={ref}
                  size="small"
                  sx={{
                    "& .MuiInputBase-inputSizeSmall": {
                      padding: "0.5rem",
                      textAlign: "center",
                    },
                  }}
                  onChange={(e) => {
                    onTypeOtp(e, index);
                  }}
                />
              ))}
              <Button
                variant="contained"
                ref={verifyBtnRef}
                {...(otpRefs[5].current &&
                  otp.length !== 6 && {
                    disabled: true,
                  })}
                onClick={onClickVerifyOTP}
                sx={{ display: { xs: "none", sm: "flex" } }}
              >
                {verifyingOtp ? (
                  <CircularProgress
                    size={20}
                    sx={{
                      color: "#fff",
                    }}
                  />
                ) : (
                  t('Verify')
                )}
              </Button>
            </Box>
            <Button
              variant="contained"
              ref={verifyBtnRef}
              {...(otpRefs[5].current &&
                otp.length !== 6 && {
                  disabled: true,
                })}
              onClick={onClickVerifyOTP}
              sx={{ display: { xs: "flex", sm: "none" } }}
            >
              {verifyingOtp ? (
                <CircularProgress
                  size={20}
                  sx={{
                    color: "#fff",
                  }}
                />
              ) : (
                t('Verify')
              )}
            </Button>
          </Box>
        </>
      </Grid>
      <Box
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        width={"100%"}
        mt={2}
        gap={0.5}
      >
        <img src={logo} alt="docnote logo" width={"100px"} />
        {/* <Divider
          orientation="vertical"
          sx={{
            mt: 0.5,
            width: "2px",
            height: "25px",
            backgroundColor: "#ABABAB",
          }}
        />
        <img src={medicaLogo} alt="medica logo" width={"120px"} style={{}} /> */}
      </Box>
    </CustomModal>
  );
};
export default SignIn;
